/*---------------------------------------------------------------------
			  Scrollbar
-----------------------------------------------------------------------*/
/* width */
::-webkit-scrollbar {
	width: 0.25em;
	height: 0.25em;
}
  /* Track */
::-webkit-scrollbar-track { 
	border-radius: 0.313em;
}
   
  /* Handle */
::-webkit-scrollbar-thumb {
	background: $primary; 
	border-radius: 0.313em;
}







