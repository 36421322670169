//cart page
.iq-product-tracker{
    overflow: hidden; 
    color: $body-color;
    .iq-tracker-position-0{
        list-style-type: none; 
        font-size: 0.813rem; 
        width: 33.33%;
        line-height: 3;
        float: left; 
        position: relative; 
        font-weight: 400;
        &.active{
            line-height: 4;
            &::after, &::before{
                background: var(--#{$variable-prefix}primary);  
                border-color: var(--#{$variable-prefix}primary);
                color: $white !important;
                transition: 500ms ease-in-out;
            }
            &::before{
                box-shadow: 0 0 0 3px $body-bg, 0 0 0 5px var(--#{$variable-prefix}primary);
            }
        }
        &.done{
            &::after, &::before{
                background: var(--#{$variable-prefix}primary);  
                border-color: var(--#{$variable-prefix}primary);
                color: $white !important;
            }
        }
        &::before{
            width: 2.5rem; 
            height: 2.5rem; 
            line-height: 2.625rem; 
            display: block; 
            font-size: 1.25rem; 
            background: $gray-300;
            border-radius: 50%; 
            margin: auto; 
            padding: 0px;
            transition: 500ms ease-in-out;
        }
        &::after{
            content: ''; 
            width: 100%; 
            height:0.125rem; 
            background: $gray-500;
            position: absolute; 
            left: 0; 
            top:1.188rem;
            z-index: -1;
        }
        &#iq-tracker-position-1{
            &::before{
                content: "1";
                color: $body-color;
            }
        }
        &#iq-tracker-position-2{
            &::before{
                content: "2";
                color: $body-color;
            }
        }
        &#iq-tracker-position-3{
            &::before{
                content: "3"; 
                color: $body-color;
            }
        }
        &:last-child{
            &::after{
                border-top-right-radius:.5rem; 
                border-bottom-right-radius:.5rem; 
                position: absolute; 
                left: -50%;
            }
        }
        &:nth-child(2){
            &::after{
                left: -50%; 
            }
        }
        &:first-child{
            &::after{
                border-top-left-radius:.5rem; 
                border-bottom-left-radius:.5rem; 
                position: absolute; 
                left: 50%; 
            }
        }
    }
}
.iq-product-tracker-card{ 
    display: none;
    opacity: 0;
    transform: translateY(330px);
    transition: 300ms ease-in-out;
    &.show{
        display: block;
        opacity: 1;
        transform: translateY(0px);
        transition: 300ms ease-in-out;
    }
}

.iq-tracking{
    width:4rem;
}